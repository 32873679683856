import React, { useState, useRef, useEffect } from "react";
import "./App.scss";
import Button from "react-bootstrap/Button";

export default function Test() {
  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(25);
  const [paused, setPaused] = useState(true);
  const [breakTime, setBreakTime] = useState(false);

  const resetButtonColor = useRef("btn btn-outline-light");
  const timer = useRef();

  const displaySeconds = seconds < 10 ? `0${seconds}` : seconds;
  const displayMinutes = minutes < 10 ? `0${minutes}` : minutes;

  const handleClick = () => {
    if (!paused) {
      clearInterval(timer.current);
    }
    resetButtonColor.current = "btn btn-outline-dark";
    setPaused(!paused);
  };

  const handleReset = () => {
    clearInterval(timer.current);
    setPaused(true);
    setBreakTime(false);
    setSeconds(0);
    setMinutes(25);
    resetButtonColor.current = "btn btn-outline-light";
  };

  useEffect(() => {
    if (paused === false) {
      if (seconds < 0 && minutes > 0) {
        setSeconds(59);
        setMinutes((minutes) => minutes - 1);
      }
      timer.current = setInterval(() => {
        setSeconds((seconds) => seconds - 1);
      }, 1000);
    }
    if (seconds === 0 && minutes === 0) {
      setPaused(true);
      clearInterval(timer.current);
      if (breakTime === false) {
        setBreakTime(true);
        setSeconds(0);
        setMinutes(5);
      } else {
        setBreakTime(false);
        setMinutes(25);
      }
    }
    return () => clearInterval(timer.current);
  }, [paused, breakTime, minutes, seconds]);

  return (
    <>
      <div className="inner">
        <h1 className="title">Pomodoro Timer</h1>
        <p className="timer">
          {displayMinutes}:{displaySeconds}
        </p>
        <div className="d-flex justify-content-center gap-5">
          <Button className="btn btn-primary" onClick={handleClick}>
            {(paused && <div>start</div>) || (!paused && <div>pause</div>)}
          </Button>
          <Button className={resetButtonColor.current} onClick={handleReset}>
            reset
          </Button>
        </div>
        {breakTime && <div>it's break time</div>}
      </div>
    </>
  );
}
