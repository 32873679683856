import "./App.scss";
// import "bootstrap/scss/bootstrap.scss";
// import Pomodoro from './Pomodoro';
import Test from "./Test";
import "./App.css";
import "./App.scss";
// import "./custom.scss";

function App() {
  return (
    <div className="App">
      {/* <Pomodoro />  */}
      <Test />
      {/* <SimpleTimer /> */}
    </div>
  );
}

export default App;
